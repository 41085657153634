<script lang="ts" context="module">
    export type Color = "blue" | "green" | "red" | "orange" | "gray" | "transparent"

    export const cls: Record<Color, string> = {
        blue: "btn-blue",
        green: "btn-green",
        red: "btn-red",
        orange: "btn-orange",
        gray: "btn-gray",
        transparent: "btn-transparent",
    }
    export const clsHover: Record<Color, string> = {
        blue: "hover:btn-blue",
        green: "hover:btn-green",
        red: "hover:btn-red",
        orange: "hover:btn-orange",
        gray: "hover:btn-gray",
        transparent: "hover:btn-transparent",
    }
    export const clsActive: Record<Color, string> = {
        blue: "active:btn-blue",
        green: "active:btn-green",
        red: "active:btn-red",
        orange: "active:btn-orange",
        gray: "active:btn-gray",
        transparent: "active:btn-transparent",
    }
</script>

<script lang="ts">
    import { Icon } from "svelte-awesome"
    import type { IconType } from "svelte-awesome/components/Icon.svelte"

    // ====================================================================================================

    let className = ""
    export { className as class }

    export let color: Color
    export let colorHover: Color | null = null
    export let colorActive: Color | null = null

    export let icon: IconType | null = null
    export let thin = false
    export let disabled = false

    export let type: "button" | "submit" | "reset" = "button"
    export let tabindex: number = 0
    export let ariaLabel = ""
</script>

<button
    class="btn
        {thin ? 'btn-thin' : 'btn-tall'}
        {cls[color]} {clsHover[colorHover ?? color]} {clsActive[colorActive ?? colorHover ?? color]}
        flex items-center justify-center gap-[10px] px-4
        {className}
    "
    {disabled}
    {type}
    {tabindex}
    aria-label={ariaLabel}
    on:click
>
    {#if icon}
        <Icon data={icon} class={!$$slots.default ? "h-5 w-5" : ""} />
    {/if}
    <slot />
</button>
